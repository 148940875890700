:root {
  // ===== colors =====
  --color--primary: #143241;
  --color--accent: #1FB6FF;
  // ===== card set =====
  --items: 1;
  --gap: 12px;
}

// ===== breakpoints =====
$mobileS: 320px;
$mobileM: 375px;
$mobileL: 425px;
$mobile: 480px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1200px;
$desktopL: 1440px;
$desktopXL: 1600px;
// ===== breakpoints =====
