*, :before, :after {
  box-sizing: border-box;
}

* {
  border: none;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  line-height: normal;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

img, picture, video, canvas, svg, iframe {
  max-width: 100%;
  display: block;
}

img {
  vertical-align: top;
}

ol, ul, li {
  list-style: none;
}

input, button, textarea, select {
  font: inherit;
}

button[disabled] {
  cursor: auto;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

:root {
  --color--primary: #143241;
  --color--accent: #1fb6ff;
  --items: 1;
  --gap: 12px;
}

.backdrop {
  z-index: 3000;
  background-color: #03213d;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  position: fixed;
  inset: 0;
  overflow: scroll;
}

.visually-hidden {
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.isHidden .loader__backdrop {
  display: none;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  background: var(--color--white);
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:first-child {
  animation-delay: -.036s;
}

.lds-roller div:first-child:after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  background: var(--color--accent);
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  background: var(--color--accent);
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  background: var(--color--accent);
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 8px;
}

body.isLoading {
  overflow: hidden;
}

body {
  color: var(--color--primary, #143241);
  font-variant-numeric: lining-nums proportional-nums;
  background-color: #fff;
  flex-direction: column;
  min-height: 100vh;
  font-family: Inter, sans-serif;
  font-size: 16px;
  display: flex;
}

body.isLoading, body.mobileMenuIsOpen {
  overflow: hidden;
}

main {
  flex-grow: 1;
}

svg {
  pointer-events: none;
}

button, a {
  cursor: pointer;
  font-variant-numeric: lining-nums proportional-nums;
}

button:focus-within, a:focus-within {
  outline-color: var(--color--accent, #1fb6ff);
}

button:hover, a:hover {
  opacity: .8;
}

.section {
  padding: 32px 0;
}

.container {
  --padding: 16px;
  padding: 0 var(--padding);
  width: 100%;
  margin: 0 auto;
}

@media screen and (width >= 480px) {
  .container {
    width: 480px;
  }
}

@media screen and (width >= 768px) {
  .container {
    --padding: 32px;
    width: 768px;
  }
}

@media screen and (width >= 1024px) {
  .container {
    width: 1024px;
  }
}

@media screen and (width >= 1200px) {
  .container {
    width: 1200px;
  }
}

@media screen and (width >= 1600px) {
  .container {
    --padding: 160px;
    width: 1600px;
  }
}

.list {
  list-style: none;
}

.link {
  text-decoration: none;
}
/*# sourceMappingURL=index.6773e44e.css.map */
