@import '/src/common/scss/reset.scss';
@import '/src/common/scss/var.scss';
@import '/src/common/scss/backdrop.scss';
@import '/src/common/scss/visually-hidden.scss';
// small components styles
@import '/src/components/loader/loader.scss';

html {
  scroll-behavior: smooth;
  scroll-padding-top: 8px;
}
body.isLoading {
  overflow: hidden;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  color: var(--color--primary, #143241);
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-variant-numeric: lining-nums proportional-nums;
  background-color: #fff;
}

body.isLoading,
body.mobileMenuIsOpen {
  overflow: hidden;
}
main {
  flex-grow: 1;
}

svg {
  pointer-events: none;
}
button, a {
  cursor: pointer;
  font-variant-numeric: lining-nums proportional-nums;

  &:focus-within { outline-color: var(--color--accent, #1FB6FF)}
  &:hover { opacity: 0.8; }
}

.section {
  padding: 32px 0;
}
.container {
  width: 100%;
  --padding: 16px;
  padding: 0 var(--padding);
  margin: 0 auto;

  @media screen and (min-width: $mobile) {
    width: $mobile;
  }
  @media screen and (min-width: $tablet) {
    width: $tablet;
    --padding: 32px;
  }
  @media screen and (min-width: $laptop) {
    width: $laptop;
  }
  @media screen and (min-width: $desktop) {
    width: $desktop;
  }
  @media screen and (min-width: $desktopXL) {
    width: $desktopXL;
    --padding: 160px;
  }
}

.list { 
  list-style: none; 
}
.link {
  text-decoration: none;
}
